export default class Navbar {
    constructor() {
        Navbar.sticky()
        Navbar.burger()
        Navbar.ecoBarHeader()
        Navbar.langs_switcher()
    }

    static sticky() {
        let last_scroll_top = 0

        $(window).on('load scroll', function () {
            const offset = 0 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const scroll_top = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scroll_top >= last_scroll_top && scroll_top > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('.header--with-banner').length > 0) {
                full = true
            }

            last_scroll_top = scroll_top

            if (scroll_top > offset) {
                navbar.removeClass('header__navbar--ontop')
                navbar.addClass('header__navbar--onscroll')
                navbar.removeClass('eco-bar-open')
            } else {
                navbar.removeClass('header__navbar--onscroll')
                navbar.addClass('header__navbar--ontop')
                if ($('.eco-bar').hasClass('eco-bar--hidden')) {
                    navbar.removeClass('eco-bar-open')
                } else {
                    navbar.addClass('eco-bar-open')
                }
            }
        })
    }

    static ecoBarHeader() {
        const navbar = $('.header__navbar')
        $(document).ready(() => {
            if ($('.eco-bar').hasClass('eco-bar--hidden')) {
                navbar.removeClass('eco-bar-open')
            } else {
                navbar.addClass('eco-bar-open')
            }
        })

        $('.eco-bar__tab').on('click', () => {
            if ($('.eco-bar').hasClass('eco-bar--hidden')) {
                navbar.removeClass('eco-bar-open')
            } else {
                navbar.addClass('eco-bar-open')
            }
        })
    }

    static burger() {
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const link = '[data-trigger="wpiris-menu-link"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        $(trigger).on('click', () => {
            $('body').toggleClass('menu-open')
            if ($('body').hasClass('menu-open')) {
                $('.eco-bar').addClass('eco-bar--hidden')
            }
        })

        // Tree navigation
        $('.menu__item__link').on('mouseenter', function (e) {
            $('.menu__wrapper .menu__item').removeClass('is-active').removeClass('has-submenu-open')
            $('.menu__wrapper .menu__item__submenu').removeClass('submenu-open')
            $(this).parent('.menu__wrapper .menu__item').addClass('is-active')
        })

        // Focus first menu item on first opening
        $(document).one('.menu-open', () => {
            $('.menu__wrapper > .menu__item:first-child > a').trigger('mouseenter')
        })

        $(more).on('click', function () {
            $('.menu__wrapper .menu__item').removeClass('is-active').removeClass('has-submenu-open')
            $('.menu__wrapper .menu__item__submenu').removeClass('submenu-open')
            $(this).closest('.menu__item').toggleClass('has-submenu-open')
            $(this).next(submenu).toggleClass('submenu-open')
        })

        $(prev).on('click', function (e) {
            e.preventDefault()
            $(this).closest('.menu__item').removeClass('has-submenu-open')
            $(submenu).removeClass('submenu-open')
        })
    }

    static langs_switcher() {
        const current_lang = $('.lang__current')
        const list_langs = $('.lang__list')

        const current_lang_value = current_lang.first().text()

        //Hide submenu
        list_langs.hide()

        //Open submenu
        $('.tools__item__languages').on('click', () => {
            $(list_langs).toggleClass('lang__list--open')
            $('.lang__list--open').fadeIn(250)
            $('.lang__list:not(.lang__list--open)').fadeOut(250)
        })

        //Remove current language on list (init)
        $('.lang__list > a').each(function () {
            if ($(this).text() === current_lang_value) {
                $(this).hide()
            }
        })

        //Change Navbar Language on click
        $('.lang__list > a').on('click', function () {
            $('.lang__list > a').show()
            $(this).hide()
            $(current_lang).text($(this).text())
        })

        // Fix current lang
        let currentLang = document.getElementsByTagName('html')[0].getAttribute('lang')
        if (currentLang.length > 2) {
            currentLang = currentLang.split('-')[0]
        }
        $('.langSwitcher_current').html(currentLang.toUpperCase())
    }
}
