export default class Backtotop {
    constructor() {
        Backtotop.back_to_top()
    }

    static back_to_top() {
        const backtotop = $('.backtotop')
        $(backtotop).click(() => {
            $('body,html').animate(
                {
                    scrollTop: 0,
                },
                600,
            )
            return false
        })
    }
}
